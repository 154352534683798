export default [
  {
    header: 'Inicio',
    icon: 'HomeIcon',
    title: 'Inicio',
    route: 'portal-dashboard',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Administración',
    header: 'Administración',
    icon: 'SettingsIcon',
    children: [
      {
        title: "VP's Direcciones",
        resource: 'administrative-management',
        children: [
          {
            title: 'Catálogo de Direcciones',
            route: 'administrative-management',
            action: 'read',
            resource: 'administrative-management',
          },
          {
            title: 'Crear Direccion',
            route: 'administrative-management-add',
            action: 'read',
            resource: 'administrative-management',
          },
        ]
      },
      {
        title: 'Centros de Costos',
        resource: 'cost-center',
        children: [
          {
            title: 'Catálogo de Centros de Costos',
            route: 'cost-centers',
            action: 'read',
            resource: 'cost-center-type',
          },
          {
            title: 'Tipos de Centros de Costos',
            route: 'center-types',
            action: 'read',
            resource: 'cost-center-type',
          },
        ]
      },
      {
        title: 'Cuentas Contables',
        resource: 'accounting-account',
        children: [
          {
            title: 'Cátalogo de Cuentas Contables',
            route: 'accounts-accounting',
            action: 'read',
            resource: 'cost-center-type',
          },
          {
            title: 'Tipos de Cuentas Contables',
            route: 'accounts-types',
            action: 'read',
            resource: 'cost-center-type',
          },
          {
            title: 'Categorías de Cuentas Contables',
            route: 'accounts-classification',
            action: 'read',
            resource: 'accounting-account-category',
          },
        ]
      },
      ///////////////////////////////////////////


      /* {
        title: 'Rangos de Hipotecas',
        route: 'mortgage-range',
        action: 'read',
        resource: 'mortgage-loan-range',
      },
      {
        title: 'Reglas y Trámites',
        route: 'reports-rentability',
        action: 'read',
        resource: 'reports-rentability',
      }, */
    ],
  },
  {
    title: 'Configuración',
    header: 'Configuración',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Gestión de Reglas Dinámicas',
        route: 'period-management',
        action: 'read',
        resource: 'period-management',
      },
      {
        title: 'Gestión de Auxiliares',
        route: 'auxiliary-list',
        action: 'read',
        resource: 'auxiliary-management',
      },
      {
        title: 'Gestión Interbancaria',
        route: 'inter-bank-homologation',
        action: 'read',
        resource: 'inter-bank-homologation',
      },
    ],
  },
  {
    title: 'Rentabilidad',
    header: 'Rentabilidad',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Gestión de Periodos',
        route: 'period-management',
        icon: 'ListIcon',
        action: 'read',
        resource: 'period-management',
      },
      {
        title: 'Aprobación de Auxiliares',
        route: 'auxiliary-approval',
        icon: 'ListIcon',
        action: 'read',
        resource: 'auxiliary-approval',
      },
      {
        title: 'Rentabilidad Financiera',
        route: 'reports-financial-rentability-dynamic',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-financial-rentability-report-dynamic',
      },
      {
        title: 'Rentabilidad Distribuida',
        route: 'reports-redistribution-dynamic',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-redistribution-report-dynamic',
      },
    ],
  },
  {
    title: 'Seguridad',
    header: 'Seguridad',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Gestion de Accesos',
        route: 'roles-list',
        icon: 'ListIcon',
        action: 'read',
        resource: 'user-roles-list',
      },
      {
        title: 'Logs de Audito',
        route: 'reports-aux',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-aux-report',
      },
    ],
  },
]
